<template>
    <div class="detail-box">
        <div class="detail-bigBox">
            <div class="detail-content">
                <div class="title-box">
                    <p>订单详情</p>
                </div>
                <div class="describe-box">
                    <p>
                        <span class="pay-success" v-show="orderDetail.payStatus==2">支付取消</span>
                        <span class="pay-success" v-show="orderDetail.payStatus==1">支付成功</span>
                        <span class="pay-success" v-show="orderDetail.payStatus==0">待支付</span>
                        <span v-show="orderDetail.payStatus==1">付款时间：{{orderDetail.createDate}}</span>
                    </p>
                    <p>订单号：{{orderDetail.tradeNo}}</p>
                    <p>下单时间：{{orderDetail.createDate}}</p>
                    <p>订单金额：￥{{orderDetail.money}}</p>
                    <p>是否使用优惠券：<span v-show="orderDetail.isCoupon == 0">否</span><span v-show="orderDetail.isCoupon == 1">是</span></p>
                    <p v-show="orderDetail.isCoupon == 1">优惠券金额：￥{{orderDetail.couponSum}}</p>
                    <p>实付金额：￥{{orderDetail.amount}}</p>
                </div>
                <div class="pay-information" v-if="payInfo!={}">
                    <p class="information-text">支付信息</p>
                    <div class="information-box">
                        <div class="tab-head">
                            <ul class="tab-ul">
                                <li class="tab-li">支付时间</li>
                                <li class="tab-li">交易号</li>
                                <li class="tab-li">支付方式</li>
                                <li class="tab-li">支付金额</li>
                            </ul>
                        </div>
                        <div class="tab-data">
                            <ul>
                                <li class="data-one">{{payInfo.payTime}}</li>
                                <li class="data-two">{{payInfo.transactionId}}</li>
                                <li class="data-three">{{payInfo.payType}}</li>
                                <li class="data-four">￥{{payInfo.amount}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-else></div>
                <div class="class-information">
                    <p class="information-text">课程信息</p>
                    <div class="information-box">
                        <div class="tab-head">
                            <ul class="tab-ul">
                                <li class="tab-li">课程编号</li>
                                <li class="tab-li">课程名称</li>
                                <li class="tab-li">有效期</li>
                                <li class="tab-li">价格</li>
                                <li class="tab-li">状态</li>
                            </ul>
                        </div>
                        <div class="class-content">
                            <ul class="class-ul">
                                <li class="class-li" v-for="(item,index) in infoList" :key="index">
                                    <div class="class-num">20191211820</div>
                                    <div class="class-name">
                                        <p @click="classJump(item.courseId)">{{item.courseName}}</p>
                                    </div>
                                    <div class="class-validity">{{item.validTime}}</div>
                                    <div class="class-price" v-if="item.price == null">￥0</div>
                                    <div class="class-price" v-else>￥{{item.price}}</div>
                                    <div class="class-state">{{item.status}}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="class-pay">
                    <div class="pay-text">
                        <p>金额总计：</p>
                        <p>￥{{orderDetail.amount}}</p>
                    </div>
                    <div class="pay-text">
                        <p>应付金额：</p>
                        <p class="pay-color">￥{{orderDetail.amount}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            infoList: [],
            payInfo: {},
            orderDetail: {},
            orderId: this.$route.query.orderId
        }
    },
    mounted() {
        this.getOrderDetail()
    },
    metaInfo: {
        title: '订单详情-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    methods: {
        // 课程跳转
        classJump(courseId) {
            this.$router.push({
                name: 'classdetail',
                params: {
                    id: courseId,
                }
            });
        },
        // 订单详情
        getOrderDetail() {
            this.axios({
                method: 'POST',
                url: 'api/v2/yun/member/order/detail.html',
                data: {
                    orderId: this.orderId
                }
            }).then((res) => {
                this.orderDetail = res.data.result.orderDetail;
                this.infoList = res.data.result.courseInfoList;
                this.payInfo = res.data.result.payInfo;
            })
        }
    }
}
</script>

<style scoped lang="scss">
    .detail-box {
        margin-top: 70px;
        .detail-bigBox {
            padding-top: 29px;
            padding-bottom: 30px;
            box-sizing: border-box;
            width: 100%;
            min-width: 1200px;
            background: #EEEEEE;
            .detail-content {
                margin: 0 auto;
                width: 1200px;
                padding-bottom: 105px;
                box-sizing: border-box;
                // height: 1100px;
                background: #FFFFFF;
                .title-box {
                    margin-bottom: 30px;
                    padding-left: 30px;
                    box-sizing: border-box;
                    width: 100%;
                    height: 82px;
                    line-height: 82px;
                    font-size: 22px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #444444;
                    border-bottom: 1px solid #EEEEEE;
                }
                .describe-box {
                    margin: 0 auto;
                    margin-bottom: 60px;
                    padding: 29px 30px;
                    box-sizing: border-box;
                    width: 1140px;
                    height: 243px;
                    background: #F3FAFD;
                    border: 1px solid #D1E0E6;
                    p {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 27px;
                        .pay-success {
                            margin-right: 19px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
                .pay-information {
                    margin: 0 auto;
                    margin-bottom: 60px;
                    width: 1140px;
                    .information-text {
                        margin-bottom: 30px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                    }
                    .information-box {
                        height: 130px;
                        border: 1px solid #DEDEDE;
                        .tab-head {
                            height: 50px;
                            background: #FAFAFA;
                            border-bottom: 1px solid #DEDEDE;
                            .tab-ul {
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                .tab-li {
                                    width: 134px;
                                    text-align: center;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 50px;
                                }
                            }
                        }
                        .tab-data {
                            padding-left: 72px;
                            box-sizing: border-box;
                            ul {
                                display: flex;
                                align-items: center;
                                li {
                                    width: 134px;
                                    line-height: 80px;
                                    text-align: center;
                                }
                                .data-one {
                                    margin-right: 153px;
                                }
                                .data-two {
                                    margin-right: 150px;
                                }
                                .data-three {
                                    margin-right: 149px;
                                }
                            }
                        }
                    }
                }
                .class-information {
                    margin: 0 auto;
                    width: 1140px;
                    .information-text {
                        margin-bottom: 30px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                    }
                    .information-box {
                        padding-bottom: 34px;
                        margin-bottom: 25px;
                        box-sizing: border-box;
                        border: 1px solid #DEDEDE;
                        .tab-head {
                            height: 50px;
                            background: #FAFAFA;
                            border-bottom: 1px solid #DEDEDE;
                            .tab-ul {
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                .tab-li {
                                    width: 134px;
                                    text-align: center;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 50px;
                                }
                            }
                        }
                        .class-content {
                            .class-ul {
                                .class-li {
                                    padding-left: 44px;
                                    padding-top: 28px;
                                    box-sizing: border-box;
                                    display: flex;
                                    align-items: baseline;
                                    div {
                                        width: 138px;
                                        text-align: center;
                                        font-size: 14px;
                                        font-family: Microsoft YaHei;
                                        font-weight: 400;
                                        color: #666666;
                                        margin-right: 89px;
                                        &:last-child {
                                            margin-right: 0;
                                        }
                                    }
                                    .class-name {
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        p {
                                            cursor: pointer;
                                        }
                                    }
                                    .class-price {
                                        margin-right: 92px;
                                    }
                                }
                            }
                        }
                    }
                }
                .class-pay {
                    margin: 0 auto;
                    width: 1140px;
                    padding-left: 991px;
                    box-sizing: border-box;
                    .pay-text {
                        display: flex;
                        align-items: baseline;
                        p {
                            font-size: 14px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #666666;
                            line-height: 36px;
                        }
                        .pay-color {
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #FF5A74;
                        }
                    }
                }
            }
        }
    }
</style>